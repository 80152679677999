export const cx = (...classNames: string[]) =>
  classNames.filter(Boolean).join(" ");

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
};

export default { cx, slugify };
