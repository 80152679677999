export const Instagram = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_113_882)">
      <path d="M16.5 0.640625H7.5C3.3585 0.640625 0 3.99913 0 8.14062V17.1406C0 21.2821 3.3585 24.6406 7.5 24.6406H16.5C20.6415 24.6406 24 21.2821 24 17.1406V8.14062C24 3.99913 20.6415 0.640625 16.5 0.640625ZM21.75 17.1406C21.75 20.0356 19.395 22.3906 16.5 22.3906H7.5C4.605 22.3906 2.25 20.0356 2.25 17.1406V8.14062C2.25 5.24563 4.605 2.89062 7.5 2.89062H16.5C19.395 2.89062 21.75 5.24563 21.75 8.14062V17.1406Z" />
      <path d="M12 6.64062C8.6865 6.64062 6 9.32712 6 12.6406C6 15.9541 8.6865 18.6406 12 18.6406C15.3135 18.6406 18 15.9541 18 12.6406C18 9.32712 15.3135 6.64062 12 6.64062ZM12 16.3906C9.933 16.3906 8.25 14.7076 8.25 12.6406C8.25 10.5721 9.933 8.89062 12 8.89062C14.067 8.89062 15.75 10.5721 15.75 12.6406C15.75 14.7076 14.067 16.3906 12 16.3906Z" />
      <path d="M18.4501 6.98963C18.8918 6.98963 19.2497 6.63167 19.2497 6.19012C19.2497 5.74856 18.8918 5.39062 18.4501 5.39062C18.0086 5.39062 17.6506 5.74856 17.6506 6.19012C17.6506 6.63167 18.0086 6.98963 18.4501 6.98963Z" />
    </g>
    <defs>
      <clipPath id="clip0_113_882">
        <rect width="24" height="24" fill="white" transform="translate(0 0.640625)" />
      </clipPath>
    </defs>
  </svg>
);

export const Facebook = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_113_889)">
      <path d="M13.5 8.89062V5.89062C13.5 5.06262 14.172 4.39062 15 4.39062H16.5V0.640625H13.5C11.0145 0.640625 9 2.65513 9 5.14062V8.89062H6V12.6406H9V24.6406H13.5V12.6406H16.5L18 8.89062H13.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_113_889">
        <rect width="24" height="24" fill="white" transform="translate(0 0.640625)" />
      </clipPath>
    </defs>
  </svg>
);

export const Twitter = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2833 10.803L23.2178 0.640625H21.1006L13.3427 9.46446L7.14656 0.640625H0L9.36984 13.9838L0 24.6406H2.11732L10.3098 15.3224L16.8534 24.6406H24L14.2827 10.803H14.2833ZM11.3833 14.1014L10.4339 12.7727L2.88022 2.20024H6.1323L12.2282 10.7326L13.1776 12.0612L21.1016 23.1519H17.8495L11.3833 14.1019V14.1014Z" />
  </svg>
);

export const Linkedin = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.367 8.14062H0V24.6406H5.367V8.14062Z" />
    <path d="M19.986 8.33413C19.929 8.31613 19.875 8.29662 19.815 8.28012C19.743 8.26362 19.671 8.25013 19.5975 8.23813C19.3125 8.18113 19.0005 8.14062 18.6345 8.14062C15.5055 8.14062 13.521 10.4161 12.867 11.2951V8.14062H7.5V24.6406H12.867V15.6406C12.867 15.6406 16.923 9.99162 18.6345 14.1406C18.6345 17.8441 18.6345 24.6406 18.6345 24.6406H24V13.5061C24 11.0131 22.2915 8.93563 19.986 8.33413Z" />
    <path d="M2.625 5.89062C4.07475 5.89062 5.25 4.71537 5.25 3.26562C5.25 1.81588 4.07475 0.640625 2.625 0.640625C1.17525 0.640625 0 1.81588 0 3.26562C0 4.71537 1.17525 5.89062 2.625 5.89062Z" />
  </svg>
);

export const ArrowDown = ({ className }: { className: string }) => (
  <svg className={className} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.75 2.91666V11.0833"
      // stroke="#00AAA9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2709 7L7.75008 11.0833L3.22925 7"
      // stroke="#00AAA9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrow = ({ className }: { className: string }) => (
  <svg className={className} height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 476.213 476.213" >
    <polygon points="476.213,238.105 400,161.893 400,223.106 0,223.106 0,253.106 400,253.106 400,314.32 " />
  </svg>
);

export const LinkSvg = ({ className }: { className: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      // fill="#00AAA9"
      d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z">
    </path>
  </svg>
);

export const HamburgerSvg = ({ className }: { className: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H15M3 6H21M3 18H21" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CloseSvg = ({ className }: { className: string }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0237 6L6.02368 18M6.02368 6L18.0237 18" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
