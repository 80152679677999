"use client";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { FC } from "react";
import Logo from "../../../public/assets/images/Logo-white.png";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from "../../../public/assets/svg/Svg";
import { footerData } from "../../data/constant";
import siteMetadata from "../../data/siteMetaData";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface FooterProps {}

const Socials = ({ name }: { name: string }) => (
  <div className={`${styles.socials} ${styles[name]}`}>
    <a
      aria-label="Instagram"
      href={siteMetadata.instagram}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram className="" />
    </a>

    <a
      aria-label="Facebook"
      href={siteMetadata.facebook}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Facebook className="" />
    </a>

    <a
      aria-label="Twitter"
      href={siteMetadata.twitter}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter className="" />
    </a>

    <a
      aria-label="Linkedin"
      href={siteMetadata.linkedin}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Linkedin className="" />
    </a>
  </div>
);

const Footer: FC<FooterProps> = (): JSX.Element => {
  const router = useRouter();

  const handleRoleChange = (nav: string) => {
    const newRole = slugify(nav);
    const newUrl = `/?role=${newRole}`;

    router.push(newUrl, { scroll: false });
    document.getElementById("appRole")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className={styles.footer}>
      <div className={styles.container} id="mainContainer">
        <div className={styles.top}>
          <Link href="/" className={styles.logo}>
            <Image alt="Logo" src={Logo} />
          </Link>

          <p>
            Quick and Reliable Deliveries. <br /> “DeliveryDoneRight”
          </p>

          <Socials name="first" />
        </div>

        <div>
          <p className={styles.title}>Company</p>

          {footerData.company.map(
            (nav, index) =>
              (nav === "About us" && (
                <Link href={`/${slugify(nav)}`} key={index.toFixed()}>
                  {nav}
                </Link>
              )) ||
              (nav === "FAQs" && (
                <Link href="#faq" key={index.toFixed()}>
                  {nav}
                </Link>
              )) || (
                <button
                  type="button"
                  onClick={() => {
                    handleRoleChange(nav);
                  }}
                  key={index.toFixed()}
                >
                  {nav}
                </button>
              )
          )}
        </div>

        <div>
          <p className={styles.title}>Legal</p>

          {footerData.legal.map((nav, index) => (
            <Link
              href={{
                pathname: "/legal",
                query: { legal: slugify(nav) },
              }}
              key={index.toFixed()}
            >
              {nav}
            </Link>
          ))}
        </div>

        <div className={styles.bottom}>
          <p className={styles.title}>CONTACT</p>

          <a
            href="mailto:hello@pointxy.app?subject=Subject%20Here&body=Message%20Body"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.email}
          >
            <span>Email:</span>
            <span>{footerData.contact[0]}</span>
          </a>

          <Socials name="second" />
        </div>
      </div>

      <div
        style={{ background: "#1D2939", height: "1px" }}
        id="mainContainer"
      />

      <div id="mainContainer" className={styles.rights}>
        <p>© PointXY 2024, All rights reserved.</p>
      </div>
    </section>
  );
};

export default Footer;
