"use client";

import Image from "next/image";
import Link from "next/link";
import { Dispatch, FC, SetStateAction, useState } from "react";
import Logo from "../../../public/assets/images/Logo.png";
import AppleStore from "../../../public/assets/svg/AppleStore";
import PlayStore from "../../../public/assets/svg/PlayStore";
import Support from "../../../public/assets/svg/Support";
import { CloseSvg, HamburgerSvg } from "../../../public/assets/svg/Svg";
import { navData } from "../../data/constant";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface HeaderProps {}

const Nav = ({
  setIsNavOpen,
}: {
  setIsNavOpen: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className={styles.nav}>
    {navData
      // .filter((nav) => {
      //   return nav !== "Home" || currentPath !== "Home";
      // })
      // .filter((nav) => slugify(nav) !== currentPath)
      .map((nav, index) => (
        <Link
          href={`/${nav === "Home" ? "" : (nav === "FAQs" && "#faq") || slugify(nav)}`}
          key={index.toFixed()}
          onClick={() => setIsNavOpen(false)}
        >
          {nav}
        </Link>
      ))}
  </div>
);

const Header: FC<HeaderProps> = (): JSX.Element => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <>
      <nav className={styles.header}>
        <div className={styles.headerContainer} id="mainContainer">
          <Link href="/" className={styles.logo}>
            <Image alt="Logo" src={Logo} />
          </Link>

          <Nav setIsNavOpen={setIsNavOpen} />

          <div className={styles.support}>
            <a
              href="mailto:hello@pointxy.app?subject=Subject%20Here&body=Message%20Body"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsNavOpen(false)}
            >
              <p>Contact Us</p>

              <Support />
            </a>

            <button
              type="button"
              onClick={() => setIsNavOpen((prev) => !prev)}
              aria-label="Hamburger menu"
              name="Hamburger menu"
            >
              {!isNavOpen ? (
                <HamburgerSvg className={styles.HamburgerSvg} />
              ) : (
                <CloseSvg className={styles.HamburgerSvg} />
              )}
            </button>
          </div>
        </div>

        <div className={`${styles.mobileNav} ${!isNavOpen && styles.close}`}>
          <div id="mainContainer">
            <Nav setIsNavOpen={setIsNavOpen} />

            <a
              href="mailto:hello@pointxy.app?subject=Subject%20Here&body=Message%20Body"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mobileSupport}
              onClick={() => setIsNavOpen(false)}
            >
              <p>Contact Us</p>

              <Support />
            </a>

            <div className={styles.cta}>
              <a
                href="http://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PlayStore />

                <p>
                  <span>Available soon on</span>
                  <br />
                  <span className={styles.available}>Google Play</span>
                </p>
              </a>

              <a
                href="http://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppleStore />

                <p>
                  <span>Available soon on</span>
                  <br />
                  <span className={styles.available}>App Store</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </nav>

      <div className={styles.fakeHeader} />
    </>
  );
};

export default Header;
