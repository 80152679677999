import {
  Icon,
  Icons,
  IconURL,
} from "next/dist/lib/metadata/types/metadata-types";

const icons: IconURL | Icon[] | Icons | null | undefined = [
  {
    url: "/icons/apple-touch-icon.png",
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/icons/apple-touch-icon.png",
  },
  {
    url: "/icons/favicon-48x48.png",
    href: "/icons/favicon-48x48.png",
    rel: "icon",
    type: "image/png",
    sizes: "48x48",
  },
  {
    rel: "mask-icon",
    url: "/safari-pinned-tab.svg",
    href: "/safari-pinned-tab.svg",
    color: "#5bbad5",
  },
];

const siteMetadata = {
  title: "PointXY - Delivering Delights: Food, Products, and Packages!",
  description:
    "PointXY is a meal and product delivery app aimed at convenience for its users",
  headerTitle: "PointXY - Delivering Delights: Food, Products, and Packages!",
  author: "PointXY",
  theme: "#ffffff",
  siteUrl: "http://www.pointxy.app",
  siteLogo: "/assets/images/icon2.png",
  socialBanner: "/assets/images/icon2.png",
  email: "hello@pointxy.app",
  twitter: "https://x.com/PointXY_app?t=qoEv9AaPy4PtN619eS9M9A&s=09",
  facebook: "https://www.facebook.com/profile.php?id=61561104980712",
  instagram: "https://www.instagram.com/pointxy_app?igsh=cjB1emxoOXMxd2Rt",
  youtube: "https://www.google.com",
  linkedin: "https://www.linkedin.com/company/pointxy/",
  language: "en-us",
  locale: "en-US",
  keywords: [
    "Lagos software developer",
    "Nigeria tech entrepreneur",
    "Modern technology solutions",
  ],
  icons,
};

export default siteMetadata;
