const Support = (): JSX.Element => {
  return <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
    <g clipPath="url(#clip0_31_18183)">
      <path d="M12.8906 8.10334C12.8906 7.84413 12.8906 7.71452 12.9297 7.59898C13.0431 7.26333 13.342 7.13307 13.6414 6.99666C13.9781 6.84331 14.1465 6.76664 14.3133 6.75315C14.5026 6.73784 14.6923 6.77863 14.8541 6.86946C15.0687 6.98988 15.2183 7.2187 15.3715 7.40476C16.0791 8.26414 16.4328 8.69382 16.5623 9.16767C16.6667 9.55006 16.6667 9.94994 16.5623 10.3323C16.3735 11.0235 15.777 11.6028 15.3355 12.139C15.1096 12.4134 14.9967 12.5505 14.8541 12.6305C14.6923 12.7214 14.5026 12.7622 14.3133 12.7469C14.1465 12.7334 13.9781 12.6567 13.6414 12.5033C13.342 12.3669 13.0431 12.2367 12.9297 11.901C12.8906 11.7855 12.8906 11.6559 12.8906 11.3967V8.10334Z"  strokeWidth="1.35" />
      <path d="M5.39038 8.10346C5.39038 7.77703 5.38121 7.48365 5.11732 7.25415C5.02133 7.17067 4.89407 7.11271 4.63956 6.99679C4.30289 6.84344 4.13455 6.76676 3.96775 6.75327C3.46732 6.7128 3.19807 7.05435 2.90948 7.40488C2.20194 8.26426 1.84818 8.69395 1.71873 9.16779C1.61426 9.55018 1.61426 9.95007 1.71873 10.3325C1.90753 11.0236 2.50402 11.6029 2.94554 12.1392C3.22385 12.4772 3.48971 12.7856 3.96775 12.747C4.13455 12.7335 4.30289 12.6568 4.63956 12.5035C4.89407 12.3875 5.02133 12.3296 5.11732 12.2461C5.38121 12.0166 5.39038 11.7232 5.39038 11.3968V8.10346Z"  strokeWidth="1.35" />
      <path d="M3.89062 6.75C3.89062 4.26472 6.24113 2.25 9.14062 2.25C12.0401 2.25 14.3906 4.26472 14.3906 6.75"  strokeWidth="1.35" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M14.3904 12.75V13.35C14.3904 14.6755 13.0472 15.75 11.3904 15.75H9.89038"  strokeWidth="1.35" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_31_18183">
        <rect width="18" height="18" fill="white" transform="translate(0.140625)" />
      </clipPath>
    </defs>
  </svg>
}

export default Support